import { send } from "../index";

export function getGoodsList(data, opts = {}) {
	//  卖品商品库/商城商品列表
  return send({
    url: "/admin/goods/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveGoods(data, opts = {}) {
	//  新增卖品商品库/商城商品
  return send({
    url: "/admin/goods/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateGoods(data, opts = {}) {
	//  编辑卖品商品库/商城商品
  return send({
    url: "/admin/goods/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getGoodsDetail(data, opts = {}) {
	//  卖品商品库/商城商品详情
  return send({
    url: "/admin/goods/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteGoods(data, opts = {}) {
	//  删除卖品商品库/商城商品
  return send({
    url: "/admin/goods/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesGoods(data, opts = {}) {
	//  模板商品上架
  return send({
    url: "/admin/goods/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesGoods(data, opts = {}) {
	//  模板商品下架
  return send({
    url: "/admin/goods/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreGoodsList(data, opts = {}) {
	//  商城商品列表
  return send({
    url: "/admin/storeGoodsStock/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreGoodsDetail(data, opts = {}) {
	//  商城商品详情
  return send({
    url: "/admin/storeGoodsStock/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveStoreGoods(data, opts = {}) {
	//  商城商品新增
  return send({
    url: "/admin/storeGoodsStock/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateStoreGoods(data, opts = {}) {
  //  商城商品更新库存
  return send({
    url: "/admin/storeGoodsStock/updateStock.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteStoreGoods(data, opts = {}) {
  //  商城商品删除
  return send({
    url: "/admin/storeGoodsStock/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getStoreGoodsStockLogList(data, opts = {}) {
  //  影院商品出入库列表
  return send({
    url: "/admin/storeGoodsStockLog/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOnShelvesStoreGoods(data, opts = {}) {
	//  商城商品上架
  return send({
    url: "/admin/storeGoodsStock/pullOnShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullOffShelvesStoreGoods(data, opts = {}) {
	//  商城商品下架
  return send({
    url: "/admin/storeGoodsStock/pullOffShelves.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function shareGoodsQR(data, opts = {}) {
	//  推广
  return send({
    url: "/admin/storeGoodsStock/shareQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateNowPrice(data, opts = {}) {
	//  更新价格
  return send({
    url: "/admin/storeGoodsStock/updateNowPrice.do",
    method: "POST",
    data,
    ...opts,
  });
}
