<template>
	<div v-show="!showModal">
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="activityName" label="活动名称">
						<a-select v-model:value="formState.activityName" placeholder="请选择活动名称" style="width: 180px;">
							<a-select-option :value="1">猜你想问</a-select-option>
							<a-select-option :value="2">模块1</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item class="ui-form__item" name="status" label="状态">
						<a-select v-model:value="formState.status" placeholder="请选择状态" allow-clear
							style="width: 180px;">
							<a-select-option :value="1">启用</a-select-option>
							<a-select-option :value="2">禁用</a-select-option>
						</a-select>
					</a-form-item>

				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" :rowKey="record => record.key"
					:scroll="{ x: 950 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div @click="onEdit">
											<a-menu-item>
												编辑
											</a-menu-item>
										</div>
										<div>
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div>
											<a-menu-item>禁用</a-menu-item>
										</div>
										<div><a-menu-item>奖品列表</a-menu-item></div>
										<div><a-menu-item>参与明细</a-menu-item></div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>

	</div>
	<temp v-if="showModal" :isEdit="isEdit" :isSee="isSee" :id="id" @back="onBack"></temp>
</template>

<script>
	import temp from './temp.vue'
	import {
		Icon
	} from '@/components/icon/icon.js';
	export default {
		components: {
			Icon,
			temp
		},
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				showAll: false,
				formState: {},
				columns: [{
					title: '抽奖活动名称',
					dataIndex: 'name',
					width: 100
				}, {
					title: '活动简介',
					dataIndex: 'introduce',
					width: 100
				}, {
					title: '状态',
					dataIndex: 'status',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					width: 100
				}],
				list: [{
					key: 1,
					name: '名称',
					introduce: '活动简介',
					status: '启用',
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {},
		methods: {
			reset() {
				this.$refs.form.resetFields();
			},
			onAdd() {
				this.showModal = true;
				this.isEdit = false;
			},
			onEdit() {
				this.showModal = true;
				this.isEdit = true;
			},
			onBack(isRef) {
				this.showModal = false;
				if (isRef) {
					this.$nextTick(() => {
						this.getData();
					})
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>