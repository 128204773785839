<template>
	<div>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">选择商品</a-button>
					<span v-if="selectedRowKeys.length !== 0"> 已选{{selectedRowKeys.length}}商品</span>
				</slot>
			</span>
			<a-modal v-model:visible="visible" width="1000px" title="商品" :maskClosable="false" @cancel="onCancel"
				@ok="onOk">
				<a-spin :spinning="loading">
					<div>
						<a-form ref="form" name="form" :model="formState" @finish="onSearch">
							<a-row>
								<a-col :span="18" style="padding-top: 10px;">
									<a-row>
										<a-form-item style="margin-right: 10px" label="商品名称" name="mainTitle">
											<a-input placeholder="请输入商品名称" v-model:value="formState.mainTitle" />
										</a-form-item>
										<a-form-item label="商品分类" name="classifyId">
											<a-select style="width: 200px;" v-model:value="formState.classifyId"
												allow-clear placeholder="请选择商品分类">
												<a-select-option v-for="item in classifyList" :key="item.id"
													:value="item.id">{{ item.title }}</a-select-option>
											</a-select>
										</a-form-item>
									</a-row>
								</a-col>
								<a-col :span="6" style="text-align: right;">
									<a-button type="primary" html-type="submit">搜索</a-button>
									<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
								</a-col>
							</a-row>
						</a-form>

						<div>
							<a-table :columns="columns" :dataSource="dataList" rowKey="id" :pagination="pagination"
								:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
								:scroll="{ x: 500 }">
							</a-table>
						</div>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</div>
</template>

<script>
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import {
		getGoodsList
	} from "../../../service/modules/goods";
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectItems: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: { // checkbox or radio
				type: String,
				default: 'checkbox'
			},
			id: { // 影院ID
				type: null,
				default: undefined
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		watch: {
			id(newVal) {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.getData();
			},
			value(newVal) {
				this.selectedRowKeys = newVal;
			}
		},
		created() {
			this.selectedRowKeys = this.value;
			this.getGoodsAllClassifyList();
		},
		data() {
			return {
				formState: {},
				targetKeys: [],
				loading: false,
				visible: false,
				selectedRowKeys: [], // 选择的id集合
				selectedListData: [], // 选择的数据集合
				selectedListIdDataMap: {}, // 每选中一项时的数据对比字典，防止分页后数据丢失
				classifyList: [],
				dataList: [],
				columns: [{
					title: '商品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '商品编码',
					dataIndex: 'id'
				}, {
					title: '商品分类',
					dataIndex: 'classifyName'
				}],
				modelRef: {},
				searchData: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		methods: {
			async getData() {
				this.loading = true;
				try {
					let ret = await getGoodsList({
						saleType: 1,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						ret.data.list.forEach(item => {
							item.id = item.id.toString();
						})
						this.dataList = ret.data.list;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async getGoodsAllClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 1
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onShowModal() {
				if (this.disabled) return;
				this.getData();
				this.visible = true;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItems))
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			resetBtn() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onCancel() {
				this.visible = false;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItems))
			},
			onOk() {
				this.$emit('update:value', JSON.parse(JSON.stringify(this.selectedRowKeys)));
				this.$emit('change', JSON.parse(JSON.stringify(this.selectedListData)));
				this.visible = false;
			},
			onChange(e) {
				this.selectedRowKeys = [];
			}
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
</style>