<template>
  <div>
    <Header :title="isEdit ? '修改抽奖活动' : '新增抽奖活动'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 5, xxl: 4}"
              :wrapperCol="{span: 18, xxl: 17 }">

        <a-row>
          <a-col :span="24">
            <a-form-item label="活动名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.name" placeholder="请输入活动名称"></a-input>
            </a-form-item>

            <a-form-item label="抽奖消耗积分" name="integral" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.integral" placeholder="请输入抽奖消耗积分"></a-input>
            </a-form-item>

            <a-form-item label="每日可抽奖数" name="drawCount" :rules="[{required: true, message: '必填项不允许为空'}]">
              <a-input v-model:value="modelRef.drawCount" placeholder="请输入每日可抽奖数"></a-input>
            </a-form-item>

            <a-form-item label="顶部背景图片" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
              <a-upload
                  v-model:file-list="topFileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
              >
                <div v-if="topFileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>

            <a-form-item label="转盘背景图片" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
              <a-upload
                  v-model:file-list="bgFileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
              >
                <div v-if="bgFileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>

            <a-form-item label="活动规则图片" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
              <a-upload
                  v-model:file-list="rulesFileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
              >
                <div v-if="rulesFileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>

            <a-form-item label="抽奖按钮图片" :rules="[{required: true, message: '必填项不允许为空'}]" extra="建议图片比例：624 : 728">
              <a-upload
                  v-model:file-list="btnFileList"
                  list-type="picture-card"
                  action="/admin/ajaxUpload.do"
                  accept="image/*"
              >
                <div v-if="btnFileList.length < 1">
                  <Icon icon="PlusOutlined"></Icon>
                  <div style="margin-top: 8px">Upload</div>
                </div>
              </a-upload>
            </a-form-item>
			
			<a-form-item label="奖品配置">
				<a-table :pagination="false" :dataSource="list" :columns="columns">
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ index + 1 }}
						</template>
						
						<template v-if="column.key === 'name'">
							<a-input v-model:value="record.name" placeholder="请输入"></a-input>
						</template>
						
						<template v-if="column.key === 'image'">
							<a-upload list-type="picture-card"
								accept="image/*"
								:show-upload-list="false"
								action="/admin/ajaxUpload.do"
								@change="(file) => onUploadChange(file, index)"
							>
								<div v-if="!record.url">
									<Icon icon="PlusOutlined"></Icon>
									<div style="margin-top: 8px">Upload</div>
								</div>
								<div v-else>
									<a-image :src="record.url"></a-image>
								</div>
							</a-upload>
						</template>
						
						<template v-if="column.key === 'num'">
							<a-input-number v-model:value="record.num" :min="0" :precision="0" placeholder="请输入"></a-input-number>
						</template>
						
						<template v-if="column.key === 'chance'">
							<a-input-number v-model:value="record.chance" :min="0" placeholder="请输入"></a-input-number>
						</template>
						
						<template v-if="column.key === 'type'">
							<a-select v-model:value="record.type" placeholder="请选择">
								<a-select-option :value="0">无奖品</a-select-option>
								<a-select-option :value="1">实物</a-select-option>
								<a-select-option :value="2">优惠券</a-select-option>
								<a-select-option :value="3">积分</a-select-option>
								<a-select-option :value="4">一卡通</a-select-option>
							</a-select>
						</template>
						
						<template v-if="column.key === 'goods'">
							<div>
								<div v-if="record.type === 0">-</div>
								<selectGoods v-if="record.type === 1"></selectGoods>
								<selectCoupon v-if="record.type === 2"></selectCoupon>
								<a-input-number v-if="record.type === 3" :min="0" :precision="0" placeholder="请输入"></a-input-number>
								<selectOneCard v-if="record.type === 4"></selectOneCard>
							</div>
						</template>
					</template>
				</a-table>
			</a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>

      </a-form>
    </a-spin>
  </div>
</template>

<script>
import Header from '@/components/header/header.vue';
import selectGoods from '@/components/select/selectGoods/index.vue';
import selectCoupon from '@/components/select/selectCoupon/index.vue';
import selectOneCard from '@/components/select/selectOneCard/index.vue';
import { Icon } from '@/components/icon/icon.js';
export default {
  components: {
    Header,
    Icon,
	selectGoods,
	selectCoupon,
	selectOneCard
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    id: {
      type: Number,
      default: 0
    },
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
	  topFileList: [],
	  bgFileList: [],
	  rulesFileList: [],
	  btnFileList: [],
	  modelRef: {},
	  columns: [{
		title: '序号',
		key: 'index',
		width: 70
	  }, {
		  title: '奖品名称',
		  key: 'name',
		  width: 190
	  }, {
		  title: '奖品图片',
		  key: 'image',
		  width: 150
	  }, {
		  title: '限制数量',
		  key: 'num',
		  width: 130
	  }, {
		  title: '中奖概率',
		  key: 'chance',
		  width: 130
	  }, {
		  title: '奖品类型',
		  key: 'type',
		  width: 150
	  }, {
		  title: '奖品',
		  key: 'goods',
		  width: 150
	  }],
	  list: [{},{},{},{},{},{},{},{},{}],
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    onPrizeOk() {

    },
	onUploadChange(info, index) {
		if (info.file.status === 'done') {
			info.fileList.forEach(file => {
				if (file.response) {
					this.list[index].url = file.response.data.imgUrl;
				}
			});
		}
	},
  }
}
</script>

<style scoped>
.ui-form__item {
  margin-right: 30px;
}
</style>